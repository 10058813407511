$button-primary-bg: $color-primary-2-base;
$button-primary-color: $color-supplement-white-base;
$button-primary-hover-bg: $color-primary-2-medium;
$button-primary-active-bg: $color-primary-2-light;
$button-primary-active-color: $color-primary-1-base;
$button-primary-padding: $spacing-4 $spacing-7;
$button-primary-padding-large: $spacing-5 $spacing-7;
$button-primary-padding-small: $spacing-2 $spacing-5;

$button-disabled-bg: #e0e1e2;
$button-disabled-color: $color-supplement-grey-medium;

$button-secondary-bg: transparent;
$button-secondary-color: $color-primary-1-base;
$button-secondary-border: 1px solid $color-primary-2-base;
$button-secondary-padding: calc(#{$spacing-4} - 1px) calc(#{$spacing-7} - 1px);
$button-secondary-padding-large: calc(#{$spacing-5} - 1px) calc(#{$spacing-7} - 1px);
$button-secondary-padding-small: calc(#{$spacing-2} - 1px) calc(#{$spacing-5} - 1px);
$button-secondary-hover-bg: $color-secondary-4-light;
$button-secondary-hover-color: $color-primary-1-base;
$button-secondary-hover-border: 1px solid $color-primary-2-medium;
$button-secondary-active-bg: $color-primary-2-light;
$button-secondary-active-color: $color-primary-1-base;
$button-secondary-disabled-bg: transparent;
$button-secondary-disabled-border: $color-supplement-grey-medium;

$button-ghost-bg: $button-secondary-bg;
$button-ghost-color: $color-primary-2-base;
$button-ghost-padding: $spacing-4 0;
$button-ghost-padding-small: $spacing-3 0;

@import './button';
