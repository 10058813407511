.message-box {
    background-color: $color-supplement-white-base;
    border-radius: $border-radius-small;
    color: $color-primary-1-base;
    display: inline-block;
    padding: $spacing-3;
    width: 100%;

    // base element definition
    &-main {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        flex-wrap: nowrap;
        gap: $spacing-5;
        max-width: 100%;
        overflow: hidden;
    }
    &-contents-col {
        display: inline-block;
        overflow: hidden;
        width: 100%;
    }
    &-header-row,
    &-subline-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: $spacing-3;
        width: 100%;
        max-width: 100%;

        &:empty {
            display: none;
        }
    }
    &-subline-row {
        margin-top: $spacing-2;
        color: $color-secondary-4-medium;
        &--highlight {
            color: $color-secondary-2-dark;
        }
    }
    &-header,
    &-subline {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
    }
    &-header,
    &-subline {
        & > * {
            vertical-align: middle;
        }
        & > * + * {
            margin-left: $spacing-3;
        }
    }
    &-subline {
        font-size: $size-font-xxsmall;
        line-height: $size-lineheight-small;
    }
    &-header {
        color: $color-secondary-2-dark;
        font-size: $size-font-xsmall;
        font-weight: 350;
        line-height: 1.5;

        &--highlight {
            font-weight: 400;
        }
    }

    // helper classes
    &-inline-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: $spacing-3;
        align-items: center;
    }
    &-divider {
        display: inline-block;
        width: 1px;
        height: $spacing-5;
        background-color: $color-supplement-grey-base;
    }
    &-spacer {
        flex-grow: 2;
    }

    // theme definition
    &--highlight {
        background-color: $color-primary-2-light;
    }

    &:hover,
    &:active {
        background-color: $color-secondary-2-light;
    }

    // overwriting FlosIcon component
    flos-icon {
        flex-shrink: 0;
    }

    &-arrow-right {
        stroke: $color-primary-2-base;
    }
}
