$site-header-desktop-height: 74px;
$site-header-mobile-height: 64px;

$site-header-bg: $color-supplement-white-base;
$site-header-mobile-bg: $color-supplement-white-base;
$site-header-border-color: $color-supplement-grey-base;
$site-header-link-color: $color-primary-1-base;
$site-header-mobile-link-color: $color-primary-1-base;
$site-header-mobile-link-bg--hover: $color-primary-2-light;
$site-header-link-color--hover: $color-primary-2-base;
$site-header-mobile-link-color--hover: $color-primary-2-base;
$site-header-mobile-navicon-color: $color-primary-1-base;

$site-header-search-form-padding-top: 0;
$site-header-search-form-bottom-border: 1px dashed $color-secondary-2-base;
$site-header-search-form-input-bg-color: $color-supplement-white-base;
$site-header-search-form-input-icon-stroke: $color-primary-2-base;

$site-header-submenu-bg-color: $color-supplement-white-base;
$site-header-submenu-link-color: $color-primary-1-base;
$site-header-submenu-link-border-bottom: 1px dashed $color-secondary-2-base;
$site-header-submenu-link-border-bottom--hover: 1px solid $color-primary-1-base;

$site-header-link-arrow-color: $color-primary-2-base;

$secondary-menu-bg-color: $color-supplement-white-base;
$secondary-menu-mobile-bg-color: $color-supplement-white-base;
$secondary-menu-link-color: $color-primary-1-base;
$secondary-menu-mobile-link-color: $color-primary-1-base;
$secondary-menu-link-color--hover: $color-primary-2-base;
$secondary-menu-mobile-link-color--hover: $color-primary-2-base;

$site-header-action-menu-top: 20px;

$site-header-sidebar-desktop-width: 504px;

@import 'parts/scss/site-header';
@import 'parts/scss/site-header-mobile';
@import 'parts/scss/site-header-segments';
@import 'parts/scss/site-header-secondary';
@import 'cvi-header-old/cvi-header';
@import 'cvi-header-old/navbar-segments';
