.stakeholder-selector {
    text-align: left;
    font-family: $font-family-base;
    letter-spacing: normal;
    text-transform: none;
    font-weight: normal;

    .icon-box-wrapper-title {
        display:inline-block;
        width: 90px;
        overflow: hidden;

        @media (min-width: $screen-sm) {
            width: 90px;
        }
    }

    .icon-box-row li {
        max-width: 90px;
    }

    .flos-button {
        width: 300px;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
