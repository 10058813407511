/*
! This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC,
The Font Bureau and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse
engineer this font software. Please contact WebType with any questions: http://www.webtype.com
*/

@font-face {
    font-family: Interstate;
    src: url('../../fonts/interstate.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: Interstate;
    src: url('../../fonts/interstate-bold.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: Interstate;
    src: url('../../fonts/interstate-light.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: Interstate;
    src: url('../../fonts/interstate-extra-light.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'Interstate Black Condensed';
    src: url('../../fonts/interstate-black-condensed.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Interstate Bold Condensed';
    src: url('../../fonts/interstate-bold-condensed.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: normal;
}

/* dm-sans-regular - latin */
@font-face {
    font-family: "DM Sans";
    src: url("../../fonts/dm-sans-v14-latin-regular.woff2") format("woff2");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

/* dm-sans-700 - latin */
@font-face {
    font-family: "DM Sans";
    src: url("../../fonts/dm-sans-v14-latin-700.woff2") format("woff2");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}
