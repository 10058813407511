$formfield-font-weight--focus: 400;
$formfield-font-weight: 350;
$formfield-label-color: $color-secondary-4-medium;
$formfield-input-color--valid: $color-supplement-success-base;
$formfield-input-color--invalid: $color-supplement-error-base;
$formfield-input-color--disabled: $color-supplement-grey-dark;
$formfield-input-color: $color-primary-1-base;
$formfield-input-bgcolor--disabled: $color-supplement-grey-light;
$formfield-input-bgcolor: $color-supplement-white-base;
$formfield-input-border-radius: 4px;
$formfield-input-bordercolor--active: $color-primary-2-base;
$formfield-input-bordercolor--disabled: $color-supplement-grey-base;
$formfield-input-bordercolor--invalid: $formfield-input-color--invalid;
$formfield-input-bordercolor--valid: $formfield-input-color--valid;
$formfield-input-bordercolor: $color-secondary-4-base;
$formfield-timing-function: cubic-bezier(0, 0, 0.2, 1);
$formfield-transition-duration: 0.2s;
$formfield-icon-size: $spacing-5;

$formfield-checked-bgcolor: $color-primary-2-light;
$formfield-checked-color: $formfield-input-bordercolor--active;
$formfield-checked-timing: cubic-bezier(0.18, 0.89, 0.32, 1.28);
$formfield-checked-duration: 0.1s;
$formfield-cursor-disabled: not-allowed;

@import './form-fields';
