.flos-tooltip {
  background-color: $color-primary-1-base;
  padding: $spacing-5;
  color: $color-supplement-white-base;
  border-radius: $border-radius-medium;
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  max-width: 320px;
}

.flos-tooltip[data-side='top'] {
  animation-name: slideUp;
}
.flos-tooltip[data-side='bottom'] {
  animation-name: slideDown;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}