$wizard-step-color: $color-secondary-4-medium;
$wizard-step-color--subtle: $color-supplement-grey-medium;
$wizard-vertical-bg-color: $color-supplement-grey-base;
$wizard-vertical-bg-color--active: $color-supplement-white-base;
$wizard-vertical-border-color: transparent;
$wizard-vertical-border-color--active: $color-primary-2-base;
$wizard-odd-size: 20px; // figma size

$transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

@import 'flos-wizard';
